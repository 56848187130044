@import '~antd/dist/antd.css';
@import './shared/theme/vars.css';
@import './shared/theme/fonts.css';
@import 'react-table/react-table.css';

body {
    font-family: 'Source-Sans-Pro', Helvetica, Arial, sans-serif;
    margin-top: 60px;
}

@media only screen and (min-width: 2559px) {
    body {
        min-height: 100%;
        position: relative;
    }
}

.header-static {
    position: relative !important;
    margin-top: 0px !important;
}

::placeholder {
    color: var(--navi60) !important;
    opacity: 1;
}

/* styles identical to placeholder */
.placeholder {
    color: var(--navi60) !important;
    opacity: 1;
}

/* css reset */
html,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

em {
    font-style: italic;
}

.wide-avatar {
    object-fit: cover;
    align-self: center;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    background-color: var(--gray);
}

/* Base icon wrapper for button*/
.icon-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 1px solid var(--mainBlue);
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer;
}

.icon-holder path {
    stroke: var(--mainBlue) !important;
}

.icon-holder-inbound {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;
    box-sizing: border-box;
    cursor: pointer;
}

.icon-holder-inbound path {
    stroke: var(--mainBlue) !important;
}

/*default override for antd modal*/
.ant-modal-content {
    background: var(--primaryWhite);
    border: 1px solid var(--gray);
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 2px 12px 40px rgba(39, 70, 91, 0.16);
}

.ant-modal-content > .ant-modal-header {
    background: none;
    margin-left: 34px;
    margin-right: 34px;
    padding: 25px 0px 25px 0px;
    border-bottom: 1px solid var(--whiteGray);
}

.ant-modal-header > .ant-modal-title {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}

.ant-modal-content > .ant-modal-body {
    margin-left: 34px;
    margin-right: 34px;
    padding: 0px;
}

.ant-modal-body > .modal-content {
    border: none;
}

.ant-modal-content > .ant-modal-footer {
    margin-left: 34px;
    margin-right: 34px;
    padding: 20px 10px 20px 0px;
}

/*override default*/
table {
    border-collapse: separate !important;
    border-spacing: 0;
}

/*override default*/
button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

/*button types*/
/*If you use them in the CustomButton component use type and only normal or filled*/
.btn-normal {
    background: var(--primaryWhite) !important;
    border: 1px solid var(--mainBlue);
    box-sizing: border-box;
    border-radius: 6px;
    margin: 0px !important;
}

.btn-filled {
    background: var(--mainBlue) !important;
    box-sizing: border-box;
    border-radius: 6px;
    margin: 0px !important;
}

.btn-filled > .button-inner {
    color: var(--primaryWhite) !important;
}

.btn-filled > .button-inner:hover {
    color: var(--lesserPrimaryWhite) !important;
}

/*tooltips inner text div*/
.ant-tooltip-inner {
    font-size: 12px;
    cursor: pointer;
    line-height: 2;
}

/*scroll style when antd component set virtual=false*/
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.tooltip-span-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.tooltip-span-text-bold {
    font-weight: 600;
    color: var(--darkNavy);
}

.flex {
    display: flex;
}

.section-splitter {
    border-top: 1px solid var(--whiteGray);
}

/* dashboard period picker dropdown styles */
.period-picker-dropdown .ant-picker-date-panel {
    width: 240px !important;
    font-size: 11px;
}

.period-picker-dropdown .ant-picker-month-panel {
    width: 240px !important;
}

.period-picker-dropdown .ant-picker-year-panel {
    width: 240px !important;
}

.period-picker-dropdown .ant-picker-date-panel .ant-picker-body {
    padding: 0;
}

.period-picker-dropdown .ant-picker-month-panel .ant-picker-content {
    height: 192px;
}

.period-picker-dropdown .ant-picker-date-panel .ant-picker-content {
    table-layout: unset;
}

.period-picker-dropdown .ant-picker-date-panel .ant-picker-content th {
    font-weight: 600;
    height: 25px !important;
    padding: 0 !important;
}

.period-picker-dropdown .ant-picker-header-view {
    font-weight: 600;
    font-size: 14px;
}

.period-picker-dropdown .ant-picker-date-panel .ant-picker-cell {
    width: 15px;
    min-width: 15px;
    padding: 0;
}

.period-picker-dropdown .ant-picker-date-panel .ant-picker-content td > div,
th > div {
    min-width: 0;
    padding: 0;
    width: 15px;
}

.period-picker-dropdown
    .ant-picker-date-panel
    .ant-picker-body
    > td.ant-picker-cell {
    padding: 0 !important;
    width: 25px !important;
    min-width: 25px !important;
}

.period-picker-dropdown .ant-picker-date-panel table.ant-picker-content,
th {
    padding: 2px !important;
    width: 33px !important;
    min-width: 33px !important;
}

.period-picker-dropdown
    .ant-picker-date-panel
    .ant-picker-cell
    .ant-picker-cell-in-view {
    width: 25px !important;
}

.period-picker-dropdown
    .ant-picker-date-panel
    .ant-picker-cell
    .ant-picker-cell-inner {
    width: 25px;
    min-width: 25px;
}

.period-picker-dropdown .range-picker-btn-footer {
    margin: 5px;
    display: flex;
    justify-content: space-evenly;
}

.period-picker-dropdown .range-picker-btn-footer .ant-btn-sm {
    border: 1px solid var(--mainBlue);
    color: var(--mainBlue);
    font-weight: 600;
    font-size: 12px;
    padding: 0px 30px;
    border-radius: 6px;
    height: 27px;
}

.period-picker-dropdown .range-picker-btn-footer .ant-btn-sm.clicked {
    border: 1px solid var(--mainBlue);
    color: white;
    background-color: var(--mainBlue);
    font-weight: 600;
    font-size: 12px;
    padding: 0px 30px;
    border-radius: 6px;
    height: 27px;
}

/* class for dropdown color */
.ant-select-item.ant-select-item-option.in-active.ant-select-item-option {
    background-color: rgb(242, 245, 248);
}

.ant-select-item.ant-select-item-option.in-active.ant-select-item-option-selected {
    background-color: rgb(242, 245, 248);
}

.unclickable,
.unclickable div {
    cursor: default !important;
}
