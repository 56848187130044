@font-face {
    font-family: 'Source-Sans-Pro';
    src: local('Source-Sans-Pro'),
        url(../../shared/theme/assets/fonts/SourceSansPro-Regular.ttf) format('opentype');
}

@font-face {
    font-family: 'Source-Sans-Pro-bold';
    src: local('Source-Sans-Pro-bold'),
        url(../../shared/theme/assets/fonts/SourceSansPro-Bold.ttf) format('opentype');
}
