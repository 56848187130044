:root {
    --linkColor: #40a9ff;
    --secondaryBlue: #1c84fe;
    --mainBlue: #03a8f5;
    --lighterBlue: #1c84fe26;
    --green: #04d294;
    --blackShadow: #111111;
    --black: #000000;
    --navy: #27465b;
    --darkNavy: #082c43;
    --darkBlue: #012840;
    --navyShadow: #09465b29;
    --darkGray: #999999;
    --gray: #c4c4c4;
    --whiteGray: #e5e5e5;
    --aqua: #21ffca;
    --yellowWhite: #fdf8e7;
    --yellow: #ffd702;
    --whiterOrange: #f2a20c;
    --orange: #fd9800;
    --darkOrange: #d93636;
    --red: #ff0000;
    --primaryWhite: #ffffff;
    --lesserPrimaryWhite: #ffffffcc;
    --navi60: #98a7b1;
    --lightNavy: #466172;
    --gutter: 25px;
    --padding: 50px;
    --defaultInputWrapWidth: 270px;
    --defaultInputWidth: 226px;
    --shadowColorOnError: 0 0 0 2px rgba(255, 0, 0, 0.2);
    --shadowSortedTableColumn: rgba(241, 244, 248, 0.4);
    --shadowSortedTableHeader: #f3f9ff;
}
